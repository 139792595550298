import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { app } from '@microsoft/teams-js';
import { Em4AuthService } from 'app/api-auth';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { APP_CONFIGURATION_TOKEN } from 'app/injection-token';
import { FrontendAppConfiguration } from 'app/services/configuration.services';
import { StorageService } from 'app/services/storage/storage.service';
import { Observable, of, tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SilentAuthenticationService {
    private readonly ACCESS_TOKEN: string = 'access_token';
    private readonly CLIENT_ID: string = 'clientId';
    private readonly AUTHENTICATED: string = 'authenticated';
    private em4Credentials: any;

    get accessToken() {
        return localStorage.getItem(this.ACCESS_TOKEN) ?? '';
    }

    get authenticated() {
        return localStorage.getItem(this.AUTHENTICATED);
    }

    get teamsClient(): boolean {
        return localStorage.getItem('teams') === 'true' ? true : false;
    }

    constructor(
        private auth: Em4AuthService,
        private authService: AuthService,
        private http: HttpClient,
        private storageService: StorageService,
        @Inject(APP_CONFIGURATION_TOKEN) private appConfig: FrontendAppConfiguration
    ) {}

    public async getCredentials(userId: string, tenantId: string): Promise<void> {
        let res = await this.http
            .post('/api/graph', {
                userId: userId,
                tenantId: tenantId,
            })
            .toPromise();
        this.em4Credentials = (res as any)['customSecurityAttributes']['Em4Cloud'];
        this.storageService.setId(this.em4Credentials.client); //localStorage.setItem('id', this.em4Credentials.client);
    }

    public async loginToEm4cloudKeycloak(): Promise<void> {
        let res = await this.auth
            .getToken(
                this.em4Credentials.client,
                'password',
                'teams',
                this.em4Credentials.username,
                this.em4Credentials.password
            )
            .toPromise();

        if (res) {
            localStorage.setItem(this.ACCESS_TOKEN, res.access_token);
            // TODO clientID should come from Proxy
            localStorage.setItem(this.CLIENT_ID, this.em4Credentials.client);
            localStorage.setItem(this.AUTHENTICATED, 'true');
            this.authService.accessToken = res.access_token;
            this.authService.getUser();
        } else {
            localStorage.setItem(this.AUTHENTICATED, 'false');
        }
    }

    public async initTeamsSession(): Promise<void> {
        console.log('initTeamsSession');
        try {
            // app;
            app.initialize().then(() => {
                app.getContext().then(async (context: app.Context) => {
                    await this.getCredentials(context.user.id, context.user.tenant.id);
                    await this.loginToEm4cloudKeycloak();
                });
            });
        } catch (error) {
            console.error(error);
        }
    }

    public loginToEm4cloudKeycloakObservable(): Observable<void> {
        return this.auth
            .getToken(
                this.appConfig.basicClientIdToBeReplaced,
                'password',
                'teams',
                this.em4Credentials.username,
                this.em4Credentials.password
            )
            .pipe(
                tap((res: any) => {
                    if (res) {
                        localStorage.setItem(this.ACCESS_TOKEN, res.access_token);
                        // TODO clientID should come from Proxy
                        localStorage.setItem(this.CLIENT_ID, this.em4Credentials.client);
                        localStorage.setItem(this.AUTHENTICATED, 'true');
                    } else {
                        localStorage.setItem(this.AUTHENTICATED, 'false');
                    }
                })
            );
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this.authenticated === 'true') {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists, and it didn't expire, sign in using it
        return of(false);
    }
}
